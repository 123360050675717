import React from 'react'
import { navigate } from 'gatsby-link'

import SeoHeader from '../components/SeoHeader'

export default function thanks() {
  return (
    <div className="p-16">
      <SeoHeader
        title='Thank you'
      />
      <h1 className="text-2xl py-8 text-center">
        Thank you for getting in touch.. we will get back to you shortly!
      </h1>
      <h2 className="text-lg text-center">
        You will now be redirected to the home page.
      </h2>
      <p className="hidden">
        {typeof window !== 'undefined' && window.document &&
          setTimeout(() => {
            navigate('/')
          }, 5000)}
      </p>
    </div>
  )
}
